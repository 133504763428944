import React from "react";
import {Box, Typography} from "@mui/material";

export const RecapTema = () => {
  return(
      <Box sx={{width:'100%'}}>
          <Box style={{width: '100%'}} sx={{mb:1}}>
              <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                  <iframe
                      src={'https://player.vimeo.com/video/778012683?h=67ebb199ff&amp;badge=0&amp;autopause=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479'}
                      frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                      style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                      title={'Tema party TAG'}></iframe>
              </div>
          </Box>
          <Typography variant={"h6"} sx={{pt:2}}>
              <a href={'/party-adecco.pdf'} target={"_blank"} style={{color:'#2ebbb3'}}>Scarica qui</a> alcuni consigli e suggestioni per il "<b style={{color:'#2ebbb3'}}>dress code</b>" della serata
          </Typography>
      </Box>
  )
}
