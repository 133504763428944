export const domande = [
    {
        id:'stile',
        titolo:"STILE/ACCESSORIO PER FESTA",
        sottotitolo:"Quando partecipi a una festa a tema, qual è secondo te l’accessorio che non può mancare?",
        opzioni:["Un cappello", "Una camicia sgargiante", "Una maschera"]
    },
    {
        id:'gruppo',
        titolo:"FAR PARTE DI UN GRUPPO",
        sottotitolo:"Se potessi far parte di un gruppo, ti piacerebbe prendere parte:",
        opzioni:["A un tour dei Rolling Stones","A una grande rapina di soldi del Monopoly","A una grande rapina di orologi da taschino"]
    },
    {
        id:'viaggiare',
        titolo:"COME VIAGGIARE",
        sottotitolo:"Se dovessi partecipare a un viaggio con un gruppo di amici, quale mezzo di trasporto sceglieresti?",
        opzioni:["Una fiat panda 4x4 con l’autoradio sempre accesa","Un furgone tutto dipinto di rosso","Un purosangue inglese"]
    },
    {
        id:'musica',
        titolo:"MUSICA",
        sottotitolo:"Se organizzassi una festa a sorpresa, quale canzone non dovrebbe mai mancare nella tua playlist?",
        opzioni:["Bella Ciao","Luna di Gianni Togni","Summertime di Bessie Smith"]
    },
    {
        id:'persone',
        titolo:"Il PERSONAGGIO DA INCONTRARE",
        sottotitolo:"Quale tra questi personaggi ti piacerebbe incontrare a una festa?",
        opzioni:["Al Capone", "Marty McFly", "Miss Keta"]
    }
]
