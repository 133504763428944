import React from "react";
import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";

export const RecapLogistica = () => {
    return (
        <Box className={'logistica'}>
            <Typography variant={'subtitle1'}>
                <b className={'title'} style={{textTransform:'none'}}>AGENDA Kick Off 2023</b>
                <br/>
                Ore 13.15: Inizio accrediti in location
                <br/>
                Ore 14.30: Inizio lavori plenaria
                <br/>
                Ore 17.30: Chiusura plenaria
                <br/><br/>
                Al termine della plenaria, il Superstudio Maxi dovrà prepararsi ad accoglierci per la parte conclusiva del nostro Kick Off 2023. Ti chiederemo di lasciare la location e di fare rientro in hotel o al tuo alloggio per prepararti per il party serale.
                <br/><br/>
                Dalle ore 21.30: Cena a buffet e festa conclusiva
                <br/>
                <br/>
                <div
                    style={{
                        width: '8rem',
                        borderBottom: '5px solid #2ebbb3',
                        margin: '0.5rem 0'
                    }}/>
                <br/>
                <b className={'title'}>Mappa plenaria</b>
                <br/>
                Ti invitiamo a visualizzare di seguito la mappa relativa alle <b>sedute</b> assegnate a ogni dipartimento e team per la sessione plenaria del Kick Off 2023. Puoi facilmente individuare la collocazione del tuo gruppo di appartenenza sulla mappa avendo cura di verificare il settore della platea e l’area (all’interno del settore) attribuita al tuo team. La mappa è disponibile per il download al <a
                href={'/mappa-sedute.pdf'} target={"_blank"}>seguente
                link</a>
                <Box sx={{width: 'min(100%, 50rem)', margin: 'auto'}}>
                    <img src={require('../assets/mappa-sedute.png')} style={{width: '100%'}}/>
                </Box>
                <div
                    style={{
                        width: '8rem',
                        borderBottom: '5px solid #2ebbb3',
                        margin: '0.5rem 0'
                    }}/>
                <br/>
                <b className={'title'}>LOCATION</b>
                <br/>
                Superstudio Maxi, <a href={'https://g.page/superstudiomaxi?share'} target={"_blank"}>via Moncucco 35, a
                Milano</a>.
                <br/><br/>
                <div
                    style={{
                        width: '8rem',
                        borderBottom: '5px solid #2ebbb3',
                        margin: '0.5rem 0'
                    }}/>
                <br/>
                <b className={'title'}>COME ARRIVARE</b>
                <br/>
                <ul>
                    <li>
                        <b>IN METROPOLITANA / TRENO</b>
                        <br/>
                        La location è comodamente raggiungibile con la LINEA M2 VERDE scendendo direttamente alla fermata di FAMAGOSTA. Da qui in pochi minuti potrai raggiungere a piedi il Superstudio Maxi.
                        <br/>
                        <br/>
                        <b>Tutte le principali stazioni di Milano (Milano Centrale/ Milano Garibaldi / Milano Cadorna)</b> sono collegate alla LINEA M2 VERDE. Entrando in metropolitana prendi il treno in direzione ASSAGO O ABBIATEGRASSO (chiesa rossa) e scendi alla fermata di FAMAGOSTA.
                        <br/>
                        <br/>
                        {/*<Box sx={{width: 'min(100%, 50rem)', margin: 'auto'}}>
                    <img src={require('../assets/mappa-metro.jpg')} style={{width: '100%'}}/>
                </Box>*/}
                        Scarica <a href={'/mappa-metro.jpg'} target={"_blank"} download>qui</a> le indicazioni per la
                        metro.
                        <br/>
                        <br/>
                    </li>
                    <li>
                        <b>IN AUTO (SE HAI PREVISTO IL PERNOTTAMENTO IN HOTEL)</b>
                        <br/>
                        Prima di recarti in location, ti chiediamo di lasciare l’auto nel parcheggio dell’hotel e poi raggiungere il Superstudio Maxi utilizzando il servizio navetta appositamente predisposto dagli alberghi:
                        <br/>
                        <br/>
                        <ul>
                            <li>STARHOTELS BUSINESS PALACE,</li>
                            <li>UNAHOTELS EXPO FIERA,</li>
                            <li>NH MILANO FIERA,</li>
                            <li>NH MILANO CONGRESS CENTRE.</li>

                        </ul>
                        <br/>
                        Il primo transfer <b>partirà dagli alberghi intorno alle ore 12.15:</b> lo staff dedicato presente in hotel ti fornirà tutte le indicazioni utili per il trasferimento.
                        <br/>
                        Ti segnaliamo che il costo del parcheggio è a tua cura ed è l’unico extra che protrai inserire in nota spese.
                        <br/><br/>
                        Ti chiediamo di raggiungere l’hotel con adeguato anticipo per non perdere il transfer che ti porterà presso il Superstudio Maxi e avere il tempo per registrarti e accomodarti con tranquillità.
                        <br/>
                        <br/>
                    </li>
                    <li>
                        <b>IN AUTO (SE NON HAI PREVISTO DI PERNOTTARE IN HOTEL)</b>
                        <br/>
                        Se intendi raggiungere la location in auto, senza pernottare in albergo, ti suggeriamo di lasciare la macchina in uno dei seguenti parcheggi:
                        <br/><br/>
                        <ul>
                            <li><b>Parking ATM Famagosta</b>: Via Giovanni Palatucci, 20142 Milano MI.  (aperto 24h su 24)
                                Qui abbiamo riservato (e prepagato) 300 posteggi presenti sul piano terrazzo. All’entrata del parcheggio, dovrai ritirare la tessera presso la colonnina di ingresso, che dovrà essere successivamente consegnata all’operatore in cassa che a sua volta ti restituirà una tessera prepagata, necessaria all’uscita, previa esibizione del QR Code personale ricevuto via mail.
                                Se i 300 posti auto prepagati dovessero esaurirsi, niente paura! Puoi comunque trovare altri posteggi nella struttura di Famagosta o, in alternativa, recarti presso gli altri parcheggi selezionati, distanti non più di 700mt dal Superstudio Maxi. Potrai caricare successivamente la ricevuta del pagamento del parcheggio in nota spese.
                            </li>
                            <li><b>ATM ROMOLO RASO</b>: Largo Tazio Nuvolari, 20142 Milano MI (aperto 24h su 24)</li>
                            <li><b>ATM ROMOLO MULTIPIANO</b>: Via Pietro Filargo, 20143 Milano MI (aperto 24h su 24)
                            </li>
                            <li><b>IULM 1</b>: Via Franco Russoli, 20143 Milano MI</li>
                            <li><b>IULM 6</b>: Via Franco Russoli, 8, 20143 Milano MI</li>
                        </ul>
                        <br/>
                        {/*<Box sx={{width: 'min(100%, 50rem)', margin: 'auto'}}>
                    <img src={require('../assets/mappa-parcheggi.jpg')} style={{width: '100%'}}/>
                </Box>*/}
                        Scarica <a href={'/mappa-parcheggi.jpg'} target={"_blank"} download>qui</a> la mappa dei
                        parcheggi.
                        <br/>
                        <br/>
                    </li>
                    <li>
                        <b>IN AEREO</b>
                        <br/>
                        Se atterri a <b>Milano Malpensa</b>, ti suggeriamo di raggiungere Milano con il collegamento ferroviario Malpensa Express per poi spostarti in location utilizzando la metropolitana LINEA M2 VERDE scendendo alla fermata di FAMAGOSTA.
                        <br/><br/>
                        Dagli aeroporti di Linate e Bergamo, invece, abbiamo predisposto un servizio navetta che ti permetterà di raggiungere direttamente il Superstudio Maxi.
                        <br/><br/>
                        da <b>Milano Linate</b>:
                        <ul>
                            <li>
                                <b>La prima navetta partirà alle ore 12.30</b> per tutti gli ospiti in arrivo fino alle
                                11.50. Il ritrovo con l’assistente sarà agli arrivi del volo AZ1605 da Alghero dalle
                                11.50.
                            </li>
                            <li>
                                <b>La seconda navetta partirà alle ore 13.00</b> per tutti gli ospiti in arrivo dalle
                                12.00 alle 12.35. Il ritrovo con l’assistente sarà agli arrivi del proprio volo.
                            </li>
                        </ul>
                        <br/>
                        da <b>Orio al Serio(BG)</b>:
                        <ul>
                            <li>
                                <b>La prima navetta partirà alle ore 9.40</b> per gli ospiti in arrivo alle ore 9.05.
                            </li>
                            <li>
                                <b>La seconda navetta partirà alle ore 12.10</b> per tutti gli ospiti in arrivo alle
                                11.35.
                                Agli arrivi troverete una hostess con cartello che indicherà l’area di pick up.
                            </li>
                        </ul>
                    </li>
                </ul>
                Se invece preferisci muoverti autonomamente o recarti prima in hotel per lasciare il bagaglio, puoi utilizzare un taxi o i mezzi pubblici. Ti chiediamo di raggiungere l’albergo con adeguato anticipo in modo da poterti recare in autonomia presso il Superstudio Maxi e avere il tempo per registrarti e accomodarti con tranquillità.
                <br/>
                <br/>
                <div
                    style={{
                        width: '8rem',
                        borderBottom: '5px solid #2ebbb3',
                        margin: '0.5rem 0'
                    }}/>
                <br/>
                <b className={'title'}>TRANSFER ORGANIZZATI DA/PER GLI HOTEL</b>
                <br/>
                Per tutti coloro che pernotteranno in hotel sono previsti i seguenti transfer:
                <ul>
                    <li><b>DALLA LOCATION AGLI HOTEL</b>. Al termine della sessione di plenaria è previsto un servizio navetta per rientrare in albergo e prepararsi al party serale.
                    </li>
                    <li><b>DAGLI HOTEL ALLA LOCATION. A partire dalle ore 20.30</b>, da tutti gli hotel sarà organizzato un nuovo transfer, che ti permetterà di tornare al Superstudio Maxi, in tempo per l’inizio della festa in programma dalle 21.30. Ti chiediamo di fare riferimento al nostro staff in loco per conoscere l’orario esatto di partenza del bus dal tuo albergo.
                    </li>
                    <li><b>DALLA LOCATION AGLI HOTEL</b>. Durante la serata abbiamo previsto un servizio navetta che ti riaccompagnerà in hotel: il punto pickup sarà organizzato in Via Rimini e le partenze sono previste&nbsp;<b>0.00&nbsp;/&nbsp;01.00&nbsp;/&nbsp;02.00</b>. Se perdi l’ultima navetta, dovrai organizzare in autonomia il tuo rientro in hotel.
                    </li>
                </ul>
                <br/>
                <div
                    style={{
                        width: '8rem',
                        borderBottom: '5px solid #2ebbb3',
                        margin: '0.5rem 0'
                    }}/>
                <br/>
                <b className={'title'}>FOOD & BEVERAGE</b>
                <br/>
                Non è previso lunch in location prima dell’inizio dell’evento. Per il buffet serale abbiamo previsto un servizio food & beverage completo ed esaustivo, in grado di far fronte anche ad eventuali <b>intolleranze alimentari</b>.
                <br/>
                <br/>
                <div
                    style={{
                        width: '8rem',
                        borderBottom: '5px solid #2ebbb3',
                        margin: '0.5rem 0'
                    }}/>
                <br/>
                <b className={'title'}>DRESS CODE e GUARDAROBA</b>
                <br/>
                Ti segnaliamo che in location sarà a tua disposizione un guardaroba per depositare cappotti ed eventuali bagagli.
                <br/><br/>
                Il <b>dress code</b> per la sessione plenaria – che inizierà alle ore 14:30 – è <b>business casual</b>,
                mentre il tema
                del party serale, in programma dalle ore 21.30, sarà ispirato a “<b>Peaky Blinders – Anni ‘20</b>”. <a
                href={'/party-adecco.pdf'} target={"_blank"}>Scarica qui</a> alcuni consigli e suggestioni per il "<b>dress
                code</b>" della serata.
                <br/><br/>
                <div
                    style={{
                        width: '8rem',
                        borderBottom: '5px solid #2ebbb3',
                        margin: '0.5rem 0'
                    }}/>
                <br/>
                <b className={'title'}>DIRETTA STREAMING</b>
                <br/>
                Tutti coloro che non potranno prendere parte in presenza al nostro <b>TAG National Kick Off 2023</b> potranno seguire la diretta dell’evento in streaming. Per accedere al live clicca <Link to={'/live-streaming'} target={"_blank"}>qui</Link>.
                <br/>
                <br/>
                <div
                    style={{
                        width: '8rem',
                        borderBottom: '5px solid #2ebbb3',
                        margin: '0.5rem 0'
                    }}/>
                <br/>
                <b className={'title'}>SOCIAL MEDIA KIT</b>
                <br/>
                A <a href={'/Kick Off 2023_Social Media Kit.pdf'} target={"_blank"}>questo link</a> puoi scaricare il social media kit che presenta le linee guida per un uso corretto, consapevole e responsabile dei social prima, durante e dopo l’evento. A tal proposito, ti invitiamo anche a interagire su <b>Work In Passion</b> per raccontare i preparativi, il viaggio e le emozioni del Kick Off 2023.
            </Typography>
        </Box>
    )
}
