import React from "react";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import header from '../assets/header.jpg'
import headerMobile from '../assets/header-mobile.jpg'

export const WithHeader = ({children}) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up("sm"))
    return (
        <Box>
            <Box sx={{width:'100%'}}>
                <img src={matches ? header : headerMobile} style={{width:'100%'}}/>
            </Box>
            {children}
        </Box>
    )
}
