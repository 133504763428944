import React from "react";
import {Container, Typography} from "@mui/material";
import {WithHeader} from "../layouts/WithHeader";

export const Home = () => {
  return(
      <WithHeader>
          <Container maxWidth={'md'} sx={{pb: 3}}>
              <Typography variant={"h4"} textAlign={'center'} fontWeight={'bold'} color={'#2ebbb3'}>
                  TAG National Kick Off 2023
              </Typography>
              <Typography sx={{py: 2}}>
                  Non vediamo l’ora di averti con noi al TAG National Kick Off 2023 di The Adecco Group Italia, che si terrà martedì <b style={{color:'#2ebbb3', fontSize:'1.3rem'}}>20&nbsp;dicembre</b> presso gli spazi del <b style={{color:'#2ebbb3', fontSize:'1.3rem'}}>Superstudio Maxi</b> (Via Moncucco, 35 20142 Milano).
              </Typography>
              <br/>
              <Typography sx={{pb: 1}}>
                  Hai delle domande? Consulta le <a href={'/faq-adecco.pdf'} style={{color: '#2ebbb3'}}
                                                    target={"_blank"}>FAQ</a>
              </Typography>
              <br/>
          </Container>
      </WithHeader>
  )
}
