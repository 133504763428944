import React from "react";
import {
    Box,
    Checkbox,
    FormControl, FormControlLabel,
    FormGroup, FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {DatePicker, DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

export const ContentSezione = ({campo, dati, handleSetDati,recap}) => {
  return(
      <Grid item xs={12} md={campo.type === 'checkbox' ? 12 : 6}>
          {(() =>{
              switch (campo.type){
                  case 'checkbox':
                      return <FormControl fullWidth>
                              <FormGroup>
                                  <FormControlLabel
                                      control={<Checkbox
                                      checked={dati.campi[campo.id]}
                                      onChange={(e) => handleSetDati(campo.id, e.target.checked)}
                                      />} label={<div>Preso atto <a href={'/privacy.pdf'} target={"_blank"} style={{color:'#2ebbb3'}}>dell’informativa privacy del titolare del trattamento Piano B Srl</a>, presto il consenso al trattamento dei miei dati personali – eventualmente anche particolari - per gli scopi e con le modalità indicate.</div>}/>
                              </FormGroup>
                          </FormControl>


                  case 'date':
                      return <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                              disabled
                              inputFormat={'DD/MM/YYYY'}
                              label={campo.title}
                              value={dati.campi[campo.id]}
                              onChange={(e) => handleSetDati(campo.id, new Date(e))}
                              renderInput={(params) => <TextField disabled sx={{width:'100%'}} variant={'standard'} {...params} />}
                          />
                      </LocalizationProvider>
                  default:
                      return <TextField disabled onChange={(e) => handleSetDati(campo.id, e.target.value)} type={campo.type} value={dati.campi[campo.id]} label={campo.title} variant={"standard"} sx={{width:'100%'}}/>
              }
          })()}
      </Grid>
  )
}
