import React, {useEffect, useState} from "react";
import {WithHeader} from "../layouts/WithHeader";
import {
    Box,
    Container,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button, CircularProgress
} from "@mui/material";
import {domande} from "../assets/data/engagementMap";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import {red} from "@mui/material/colors";
import MuiAlert from "@mui/material/Alert";
import {useNavigate, useParams} from "react-router-dom";
import {ContattiSegreteria} from "../components/ContattiSegreteria";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const startData = {
    stile: "",
    gruppo: "",
    viaggiare: "",
    musica: "",
    persone: ""
}

export const Engagement = () => {
    const {hash} = useParams()
    const [postFailed, setPostFailed] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [engagementComplete, setEngagementComplete] = useState(false)
    const [dati, setDati] = useState(startData)
    const [sending, setSending] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        getDati()
    },[])

    const getDati = async () => {
        setLoading(true)
        await axios.get('https://adecco-api-s3g2757oya-ey.a.run.app/user/' + hash)
            .then(r => {
                let d = startData
                Object.keys(startData).map(k => d[k] = r.data.user.engagement[k] ?? startData[k])
                setDati(d)
            })
            .catch(e => setError(true))
        setLoading(false)
    }

    const inviaDati = async (e) => {
        e.preventDefault()
        setSending(true)
        await axios.post('https://adecco-api-s3g2757oya-ey.a.run.app/engagement/'+hash, dati)
            .then(() => setEngagementComplete(true))
            .catch(() => setPostFailed(true))
        setSending(false)
    }

    const handleChangeDati = (id, value) => {
        setDati(prevState => ({...prevState, [id]: value}))
    }

    return (
        <Box>
            <Snackbar open={postFailed} autoHideDuration={6000} onClose={() => setPostFailed(false)}>
                <Alert onClose={() => setPostFailed(false)} severity="error" sx={{width: '100%'}}>
                    C'è stato un errore, si prega di riprovare
                </Alert>
            </Snackbar>
            <Container maxWidth={'md'} sx={{pb: 3}}>
                {loading ?
                    <Box sx={{width: '100%', textAlign: 'center'}}>
                        <CircularProgress/>
                    </Box>
                    :
                    <Box>
                        {error &&
                            <Container maxWidth={"sm"} sx={{pt: 2}}>
                                <Typography textAlign={"center"} variant={"h5"} fontWeight={'bold'}
                                            color={red[400]}>
                                    Il link è errato, si prega di riprovare
                                </Typography>
                            </Container>
                        }
                        {engagementComplete &&
                            <Container maxWidth={"sm"} sx={{pt: 2}}>
                                <Typography textAlign={"center"} variant={"h5"} fontWeight={'bold'}
                                            color={'white'}>
                                    Grazie per aver risposto!<br/>STAY TUNED: <span style={{color:'#2ebbb3'}}>lunedì 5 dicembre</span> sveleremo il tema della festa!
                                </Typography>
                            </Container>
                        }
                        {(!error && !engagementComplete) &&
                            <form onSubmit={inviaDati}>
                                <Typography variant={"h5"} fontWeight={"bold"}>
                                    Consulta qui sotto le tue risposte per il tema del party
                                </Typography>
                                {domande.map((d, index) =>
                                    <Box key={index} sx={{py: 2}}>
                                        <Typography variant={"h5"} fontWeight={"bold"}>
                                            {`Domanda ${index + 1}`}
                                        </Typography>
                                        <div
                                            style={{
                                                width: '8rem',
                                                borderBottom: '5px solid #2ebbb3',
                                                margin: '0.5rem 0'
                                            }}/>
                                        <Typography variant={"h6"}>
                                            {d.sottotitolo}
                                        </Typography>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={dati[d.id]}
                                                onChange={(e) => handleChangeDati(d.id, e.target.value)}
                                            >
                                                {d.opzioni.map((o, k) =>
                                                    <FormControlLabel key={k} value={o} control={<Radio disabled/>}
                                                                      label={o}/>
                                                )}
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                )}
                            </form>
                        }
                    </Box>
                }
                <ContattiSegreteria/>
            </Container>
        </Box>
    )
}
