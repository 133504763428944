import React from "react";
import {Box, Divider, Typography} from "@mui/material";

export const ContattiSegreteria = () => {
  return(
      <Box sx={{pt:2}}>
          <Divider/>
          <Typography variant={'body1'} sx={{mt:2}}>
              <b>Contatti Segreteria Organizzativa Kick Off 2023</b>
              <br/>
              Tel: 02 80897146
              <br/>
              E-mail: segreteria@kickoff2023tag.it
              <br/>
              Orari: Lun-Ven H. 10:00/13:00 – 14:00/17:00
          </Typography>
      </Box>
  )
}
