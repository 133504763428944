import React from "react";
import {Box, Container, Typography} from "@mui/material";

export const LiveStreaming = () => {
  return(
      <Container maxWidth={'lg'} sx={{height:'max(100vh, 45rem)', display:'flex', alignItems:'center'}}>
          <Box sx={{width:'100%'}}>
              <Typography variant={"h6"} textAlign={"center"} sx={{pb:2}}>
                  L'evento inizierà il <b style={{color:'#2ebbb3'}}>20 dicembre alle ore 14:30</b>
              </Typography>
              <img style={{width:'100%'}} src={require('../assets/tappo.jpg')}/>
          </Box>
      </Container>
  )
}
